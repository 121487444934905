.section-title {
  font-size: 3em;
  text-align: center;
  margin-bottom: 30px;
  color: #ffffff; /* Color blanco */
}

.home-about-body {
  font-size: 1.2em;
  line-height: 1.6;
  color: #ffffff; /* Color blanco */
}


.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.text-left {
  text-align: justify;
  padding-left: 0px;
}


.text-left li {
  text-align: justify;
  margin-left: -20px; /* Ajuste cette valeur selon le besoin */
}

.card-hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(255, 89, 89, 0.603);
  color: #ffffff; /* Aseguramos que el texto en hover también sea blanco */
}

.card {
  border: none;
  border-radius: 10px;
  background-color: #ff000000;
  box-shadow: 0 4px 8px rgba(255, 111, 111, 0.301);
  color: #ffffff; /* Color blanco para el texto */
}

.card-body {
  padding: 20px;
  color: #ffffff; /* Color blanco para el texto dentro del cuerpo de la tarjeta */
}

.card h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #ffffff; /* Color blanco para los títulos de las tarjetas */
}

.transparent-card {
  background-color: transparent !important;
  border: none !important;
  color: #ffffff !important; /* Aseguramos que el texto sea blanco en fondo transparente */
}

.card ul {
  padding-left: 20px;
  text-align: left;
  color: #ffffff; /* Color blanco para el texto en las listas */
}

.card ul li {
  margin-bottom: 10px;
  list-style-position: inside;
  color: #ffffff; /* Color blanco para los elementos de las listas */
}

.icon {
  margin-right: 10px;
  color: #ffffff; /* Color blanco para los íconos */
}

/* Estilos para los títulos e íconos dentro de las tarjetas en hover */
.card-hover:hover .card-body,
.card-hover:hover .card ul li,
.card-hover:hover .card h2,
.card-hover:hover .card p {
  color: #ffffff; /* Aseguramos que el texto se mantenga blanco incluso en hover */
}

/* Estilo para los íconos dentro de las tarjetas */
.card h2 .icon {
  color: #ffffff; /* Blanco para los íconos */
}

/* Estilos para los elementos en las pantallas de tamaño pequeño (móviles) */
@media (max-width: 768px) {
  .section-title {
    font-size: 2em;
  }

  .home-about-body {
    font-size: 1em;
  }

  .card h2 {
    font-size: 1.2em;
  }

  .card ul li {
    font-size: 0.9em;
  }

  .icon {
    font-size: 1em;
  }
}
